import type {
  PathValue,
  FieldValues,
  UseFormReturn,
  Path,
} from 'react-hook-form';

import { getCsrfToken } from 'lib/request';

export function formToSwitchProps<TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
  path: Parameters<UseFormReturn<TFieldValues>['setValue']>[0],
) {
  return {
    isChecked: form.watch(path) as boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
      form.setValue(
        path,
        event.target.checked as PathValue<TFieldValues, Path<TFieldValues>>,
        {
          shouldDirty: true,
        },
      ),
  };
}

export type FormMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

export const submitForm = (
  action: string,
  method: FormMethod,
  fields?: Record<string, string | number | boolean | null | undefined>,
  newTab?: boolean,
) => {
  fields = { ...(fields || {}) };

  if (method !== 'get' && method !== 'post') {
    fields._method = method;
  }

  if (method !== 'get') {
    // is this needed?
    fields.authenticity_token = getCsrfToken();
  }

  const form = document.createElement('form');

  for (const [key, value] of Object.entries(fields)) {
    if (value == null) continue;
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value.toString();
    form.appendChild(input);
  }

  form.action = action;
  form.method = method === 'get' ? 'get' : 'post';
  if (newTab) form.target = '_blank';
  form.style.display = 'none';

  document.body.appendChild(form);
  form.submit();
  setTimeout(() => {
    form.remove();
  }, 100);
};

export const appLogout = () => {
  submitForm('/users/sign_out', 'delete');
};
