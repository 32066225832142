import {
  Box,
  BoxProps,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Fragment } from 'react';

import { PermissionKey, UserData, useUserData } from 'lib/userData';
import { useIsMobile } from 'lib/hooks/breakpoints';
import { Link } from 'components/Link';
import { BurgerMenuIcon } from 'bundles/Classroom/icons';
import { TextLogo } from 'components/Logo';
import { ROUTES as OPS_ROUTES } from 'bundles/OperationsDashboard/routes';
import { ROUTES as INS_ROUTES } from 'bundles/InstructorDashboard/routes';
import { appLogout } from 'lib/utils/formHelpers';

import { NotificationAlertsMenu } from './NotificationAlertsMenu';

export type NavItem = {
  content: React.ReactNode;
  href?: string;
  permissions?: readonly PermissionKey[];
};

export const NAV_ITEMS: NavItem[] = [
  {
    content: '🔒 Admin',
    href: '/activeadmin',
    permissions: [
      'admin',
      'coach',
      'externship_coordinator',
      'account_manager',
    ],
  },
  {
    content: '🏥 Clinics',
    href: '/activeadmin/clinic_leads',
    permissions: ['externship_coordinator', 'account_manager'],
  },
  {
    content: '🏢 Externship Companies',
    href: '/activeadmin/externship_companies',
    permissions: ['externship_coordinator', 'account_manager'],
  },
  {
    content: '📚 Classroom',
    href: '/course',
    permissions: ['admin', 'student', 'instructor'],
  },
  {
    content: '📝 Content Editor',
    href: OPS_ROUTES.content_editor_dashboard.path(),
    permissions: ['admin'],
  },
  {
    content: '🎓 Learning',
    href: INS_ROUTES.home.path(),
    permissions: ['admin', 'instructor'],
  },
  {
    content: '🤝 Coaching',
    href: OPS_ROUTES.coaching.path(),
    permissions: ['admin', 'coach'],
  },
  {
    content: '📄 Resumes',
    href: OPS_ROUTES.resumes.path(),
    permissions: ['admin', 'resume_editor'],
  },
  {
    content: '📈 Externships',
    href: OPS_ROUTES.externship.path(),
    permissions: [
      'admin',
      'externship_coordinator',
      'coach',
      'account_manager',
    ],
  },
];

// very similar to `ProfileMenu` in `bundles/Classroom/Nav.tsx`
const ProfileMenu: React.FC<
  MenuButtonProps & {
    user: UserData | null | undefined;
    navItems: NavItem[];
    showLogin?: boolean;
  }
> = ({ user, navItems, showLogin = true, ...menuButtonProps }) => {
  const isMobile = useIsMobile();

  const items: React.ReactNode[] = [];
  if (user)
    items.push(
      <Fragment key="username">
        <Text color="brand.500" fontWeight="bold" px="3">
          {user.name}
        </Text>
        <MenuDivider />
      </Fragment>,
    );

  if (isMobile && navItems.length > 0)
    items.push(
      <Fragment key="mobile_nav">
        {navItems.map((nit, i) =>
          nit.href ? (
            <MenuItem key={i} as={Link} href={nit.href}>
              {nit.content}
            </MenuItem>
          ) : (
            <MenuItem key={i} tabIndex={-1}>
              {nit.content}
            </MenuItem>
          ),
        )}
        <MenuDivider />
      </Fragment>,
    );

  if (user)
    items.push(
      <Fragment key="profile">
        <MenuItem as={Link} href="/profile" icon={<>🖋️</>}>
          Edit Profile
        </MenuItem>
        <MenuDivider />
      </Fragment>,
    );

  if (user)
    items.push(
      <MenuItem key="logout" as="button" onClick={appLogout} icon={<>👋</>}>
        Logout
      </MenuItem>,
    );
  else if (showLogin)
    items.push(
      <MenuGroup key="login" title="Already have an account?">
        <MenuItem
          as={Link}
          href="/users/sign_in"
          fontWeight="500"
          color="brand.500"
        >
          Login
        </MenuItem>
      </MenuGroup>,
    );

  if (items.length === 0) return null;

  return (
    <Menu closeOnBlur isLazy>
      <MenuButton
        {...menuButtonProps}
        as={IconButton}
        variant="ghost"
        icon={<BurgerMenuIcon size="50%" />}
        color="gray.500"
        aria-label="Settings"
      />
      <MenuList
        w="240px"
        maxH="80vh"
        overflowY="auto"
        display="flex"
        flexDir="column"
        alignItems="stretch"
      >
        {items}
      </MenuList>
    </Menu>
  );
};

export const NAVBAR_HEIGHT = 48;

export const MainNav: React.FC<
  Omit<BoxProps, 'title'> & {
    title?: React.ReactNode;
    sticky?: boolean;
    navItems?: NavItem[];
    showLogin?: boolean;
  }
> = ({
  title,
  sticky,
  navItems: extraNavItems = [],
  showLogin = true,
  ...containerProps
}) => {
  const user = useUserData(true);
  const permissions = user?.permissions || [];

  const navItems: NavItem[] = [...NAV_ITEMS, ...extraNavItems].filter(
    (nit) =>
      !nit.permissions || nit.permissions.some((p) => permissions.includes(p)),
  );

  return (
    <Box
      as="nav"
      aria-label="Primary"
      bg="white"
      shadow="md"
      zIndex={4}
      h={`${NAVBAR_HEIGHT}px`}
      px="2"
      position={sticky ? 'sticky' : 'relative'}
      top={0}
      w="full"
      display="flex"
      alignItems="center"
      gap={2}
      {...containerProps}
    >
      <Box
        as="a"
        href="/" // home page link
        _hover={{
          bg: 'gray.100',
        }}
        display="flex"
        alignItems="center"
        px="3"
        py="1"
        rounded="lg"
        color="chakra-body-text"
        textDecor="none"
        fontSize="22px"
        fontWeight="bold"
      >
        <TextLogo mb="-3px" />
        {title ? (
          <Text as="span" ml="2">
            {title}
          </Text>
        ) : null}
      </Box>

      <Box flex="1" />

      {navItems.map((nit, i) =>
        nit.href ? (
          <Button
            as={Link}
            key={i}
            href={nit.href}
            display={{
              base: 'none',
              md: 'inline-flex',
            }}
            variant="outline"
            colorScheme="gray"
            size="sm"
          >
            {nit.content}
          </Button>
        ) : (
          <Box
            key={i}
            display={{
              base: 'none',
              md: 'inline-flex',
            }}
          >
            {nit.content}
          </Box>
        ),
      )}

      {user ? <NotificationAlertsMenu flex={0} /> : null}

      <ProfileMenu
        showLogin={showLogin}
        user={user}
        navItems={navItems}
        flex={0}
      />
    </Box>
  );
};
