import { useBreakpointValue } from '@chakra-ui/react';

import { useSessionContext } from 'components/SessionContext';

export const useIsMobile = (): boolean => {
  const isUserAgentMobile = useSessionContext()?.viewport === 'mobile';

  return !!useBreakpointValue(
    { base: true, md: false },
    isUserAgentMobile ? 'base' : 'md',
  );
};
