import ReactOnRails from 'react-on-rails';

import { withAppContainer } from 'components/AppContainer';
import { PageAlerts } from 'components/PageAlerts';
import { MainNav } from 'components/navbar/MainNav';

// this file contains the React components that are rendered on most pages of the website

ReactOnRails.register({
  PageAlerts: withAppContainer(PageAlerts),
  MainNav: withAppContainer(MainNav),
});
